import React from 'react';
import {
  Added,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2019" subnav="release-notes">
      <div id="March2019" className={styles.release}>

        <Paragraph className="uni-margin--one--vert">
          Remember those "bigger, relatively more exciting things" we mentioned? They're here! And they’re big. As for
          excitement, that's up to you. But we're talking new components like the coveted data table and an overlay,{' '}
          <em>and</em> a refreshed React Native repo in prep for future goodness. You ready?
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                The first of many guidelines for how and when to use{' '}
                <Link href="/components/data-table/design">data tables</Link>.
              </Added>
              <Added>
                <Link href="/components/overlay/design">Overlay</Link> guidelines to help <em>layer</em> a workflow.
              </Added>
              <Added>
                Clarity around writing <Link href="/words/content-elements/event-details#Versus">versus</Link> across
                the product.
              </Added>
              <Improved>
                Date and time guidelines for a more thorough rundown of{' '}
                <Link href="/words/content-elements/date-time#Life%20Span">life span</Link> and{' '}
                <Link href="/words/content-elements/date-time#Durations">duration</Link>.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.28.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.28.1"
            />

            <div className={styles.changesList}>
              <Added>
                The <Link href="/components/data-table/design">data table</Link>. It's large—but also medium, small and
                x-small.
              </Added>
              <Added>
                That <Link href="/components/overlay/design">overlay</Link> component for context, because context is the
                best.
              </Added>
              <Improved>Border-radius styling on any component with rounded corners.</Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="6.1.1"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v6.1.1"
            />

            <div className={styles.changesList}>
              <Improved>React by upgrading to version 0.59.</Improved>
              <Removed>Windows support.</Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>
                <Link href="/components/data-table/design/">Data tables</Link> for all sizes.
              </Added>
              <Added>
                Our friend the <Link href="/components/overlay/design">overlay</Link>.
              </Added>
              <Improved>
                Styling for the <Link href="/components/action-bar/design/">action bar</Link>,{' '}
                <Link href="/components/action-list/design/">action list</Link>,{' '}
                <Link href="/components/alert/design/">alert</Link>, <Link href="/components/modal/design/">modal</Link>,{' '}
                <Link href="/components/notifications/notice/design/">notice</Link> and{' '}
                <Link href="/components/notifications/toast/design/">toast</Link>. Phew!
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
